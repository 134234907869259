exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-air-freight-js": () => import("./../../../src/pages/AirFreight.js" /* webpackChunkName: "component---src-pages-air-freight-js" */),
  "component---src-pages-career-career-component-js": () => import("./../../../src/pages/Career/CareerComponent.js" /* webpackChunkName: "component---src-pages-career-career-component-js" */),
  "component---src-pages-career-career-js": () => import("./../../../src/pages/Career/Career.js" /* webpackChunkName: "component---src-pages-career-career-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/Career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-company-first-section-js": () => import("./../../../src/pages/Company/FirstSection.js" /* webpackChunkName: "component---src-pages-company-first-section-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-company-management-section-js": () => import("./../../../src/pages/Company/ManagementSection.js" /* webpackChunkName: "component---src-pages-company-management-section-js" */),
  "component---src-pages-company-mission-and-vision-js": () => import("./../../../src/pages/Company/MissionAndVision.js" /* webpackChunkName: "component---src-pages-company-mission-and-vision-js" */),
  "component---src-pages-company-our-journey-section-js": () => import("./../../../src/pages/Company/OurJourneySection.js" /* webpackChunkName: "component---src-pages-company-our-journey-section-js" */),
  "component---src-pages-company-secon-section-js": () => import("./../../../src/pages/Company/SeconSection.js" /* webpackChunkName: "component---src-pages-company-secon-section-js" */),
  "component---src-pages-contact-address-card-js": () => import("./../../../src/pages/Contact/AddressCard.js" /* webpackChunkName: "component---src-pages-contact-address-card-js" */),
  "component---src-pages-contact-contact-js": () => import("./../../../src/pages/Contact/Contact.js" /* webpackChunkName: "component---src-pages-contact-contact-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-get-a-qoute-get-a-qoute-js": () => import("./../../../src/pages/GetAQoute/GetAQoute.js" /* webpackChunkName: "component---src-pages-get-a-qoute-get-a-qoute-js" */),
  "component---src-pages-get-a-qoute-js": () => import("./../../../src/pages/get-a-qoute.js" /* webpackChunkName: "component---src-pages-get-a-qoute-js" */),
  "component---src-pages-home-about-section-js": () => import("./../../../src/pages/Home/AboutSection.js" /* webpackChunkName: "component---src-pages-home-about-section-js" */),
  "component---src-pages-home-hero-section-js": () => import("./../../../src/pages/Home/HeroSection.js" /* webpackChunkName: "component---src-pages-home-hero-section-js" */),
  "component---src-pages-home-news-section-js": () => import("./../../../src/pages/Home/NewsSection.js" /* webpackChunkName: "component---src-pages-home-news-section-js" */),
  "component---src-pages-home-our-partner-companies-jsx": () => import("./../../../src/pages/Home/OurPartnerCompanies.jsx" /* webpackChunkName: "component---src-pages-home-our-partner-companies-jsx" */),
  "component---src-pages-home-service-js": () => import("./../../../src/pages/Home/Service.js" /* webpackChunkName: "component---src-pages-home-service-js" */),
  "component---src-pages-home-third-section-js": () => import("./../../../src/pages/Home/ThirdSection.js" /* webpackChunkName: "component---src-pages-home-third-section-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-land-transportation-js": () => import("./../../../src/pages/LandTransportation.js" /* webpackChunkName: "component---src-pages-land-transportation-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/Maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-news-and-information-js": () => import("./../../../src/pages/newsAndInformation.js" /* webpackChunkName: "component---src-pages-news-and-information-js" */),
  "component---src-pages-news-news-js": () => import("./../../../src/pages/News/News.js" /* webpackChunkName: "component---src-pages-news-news-js" */),
  "component---src-pages-news-news-template-js": () => import("./../../../src/pages/News/NewsTemplate.js" /* webpackChunkName: "component---src-pages-news-news-template-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/OurServices.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-our-services-our-services-js": () => import("./../../../src/pages/OurServices/OurServices.js" /* webpackChunkName: "component---src-pages-our-services-our-services-js" */),
  "component---src-pages-our-services-service-template-js": () => import("./../../../src/pages/OurServices/ServiceTemplate.js" /* webpackChunkName: "component---src-pages-our-services-service-template-js" */),
  "component---src-pages-our-services-tile-content-js": () => import("./../../../src/pages/OurServices/TileContent.js" /* webpackChunkName: "component---src-pages-our-services-tile-content-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/PrivacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sea-freight-js": () => import("./../../../src/pages/SeaFreight.js" /* webpackChunkName: "component---src-pages-sea-freight-js" */),
  "component---src-pages-standard-trading-conditions-js": () => import("./../../../src/pages/standardTradingConditions.js" /* webpackChunkName: "component---src-pages-standard-trading-conditions-js" */),
  "component---src-pages-standard-trading-conditions-list-js": () => import("./../../../src/pages/StandardTradingConditions/List.js" /* webpackChunkName: "component---src-pages-standard-trading-conditions-list-js" */),
  "component---src-pages-standard-trading-conditions-standard-trading-conditions-js": () => import("./../../../src/pages/StandardTradingConditions/StandardTradingConditions.js" /* webpackChunkName: "component---src-pages-standard-trading-conditions-standard-trading-conditions-js" */),
  "component---src-template-news-js": () => import("./../../../src/template/news.js" /* webpackChunkName: "component---src-template-news-js" */),
  "component---src-template-service-js": () => import("./../../../src/template/Service.js" /* webpackChunkName: "component---src-template-service-js" */)
}

